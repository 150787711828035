exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-en-tsx": () => import("./../../../src/pages/about-us.en.tsx" /* webpackChunkName: "component---src-pages-about-us-en-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-en-tsx": () => import("./../../../src/pages/contact-us.en.tsx" /* webpackChunkName: "component---src-pages-contact-us-en-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-dental-en-tsx": () => import("./../../../src/pages/industries/dental.en.tsx" /* webpackChunkName: "component---src-pages-industries-dental-en-tsx" */),
  "component---src-pages-industries-dental-tsx": () => import("./../../../src/pages/industries/dental.tsx" /* webpackChunkName: "component---src-pages-industries-dental-tsx" */),
  "component---src-pages-industries-en-tsx": () => import("./../../../src/pages/industries.en.tsx" /* webpackChunkName: "component---src-pages-industries-en-tsx" */),
  "component---src-pages-industries-health-care-en-tsx": () => import("./../../../src/pages/industries/health-care.en.tsx" /* webpackChunkName: "component---src-pages-industries-health-care-en-tsx" */),
  "component---src-pages-industries-health-care-tsx": () => import("./../../../src/pages/industries/health-care.tsx" /* webpackChunkName: "component---src-pages-industries-health-care-tsx" */),
  "component---src-pages-industries-law-firm-en-tsx": () => import("./../../../src/pages/industries/law-firm.en.tsx" /* webpackChunkName: "component---src-pages-industries-law-firm-en-tsx" */),
  "component---src-pages-industries-law-firm-tsx": () => import("./../../../src/pages/industries/law-firm.tsx" /* webpackChunkName: "component---src-pages-industries-law-firm-tsx" */),
  "component---src-pages-industries-manufacturing-en-tsx": () => import("./../../../src/pages/industries/manufacturing.en.tsx" /* webpackChunkName: "component---src-pages-industries-manufacturing-en-tsx" */),
  "component---src-pages-industries-manufacturing-tsx": () => import("./../../../src/pages/industries/manufacturing.tsx" /* webpackChunkName: "component---src-pages-industries-manufacturing-tsx" */),
  "component---src-pages-industries-non-profit-en-tsx": () => import("./../../../src/pages/industries/non-profit.en.tsx" /* webpackChunkName: "component---src-pages-industries-non-profit-en-tsx" */),
  "component---src-pages-industries-non-profit-tsx": () => import("./../../../src/pages/industries/non-profit.tsx" /* webpackChunkName: "component---src-pages-industries-non-profit-tsx" */),
  "component---src-pages-industries-real-estate-en-tsx": () => import("./../../../src/pages/industries/real-estate.en.tsx" /* webpackChunkName: "component---src-pages-industries-real-estate-en-tsx" */),
  "component---src-pages-industries-real-estate-tsx": () => import("./../../../src/pages/industries/real-estate.tsx" /* webpackChunkName: "component---src-pages-industries-real-estate-tsx" */),
  "component---src-pages-industries-restaurant-en-tsx": () => import("./../../../src/pages/industries/restaurant.en.tsx" /* webpackChunkName: "component---src-pages-industries-restaurant-en-tsx" */),
  "component---src-pages-industries-restaurant-tsx": () => import("./../../../src/pages/industries/restaurant.tsx" /* webpackChunkName: "component---src-pages-industries-restaurant-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-pricing-en-tsx": () => import("./../../../src/pages/pricing.en.tsx" /* webpackChunkName: "component---src-pages-pricing-en-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-services-accounting-en-tsx": () => import("./../../../src/pages/services/accounting.en.tsx" /* webpackChunkName: "component---src-pages-services-accounting-en-tsx" */),
  "component---src-pages-services-accounting-tsx": () => import("./../../../src/pages/services/accounting.tsx" /* webpackChunkName: "component---src-pages-services-accounting-tsx" */),
  "component---src-pages-services-auditing-en-tsx": () => import("./../../../src/pages/services/auditing.en.tsx" /* webpackChunkName: "component---src-pages-services-auditing-en-tsx" */),
  "component---src-pages-services-auditing-tsx": () => import("./../../../src/pages/services/auditing.tsx" /* webpackChunkName: "component---src-pages-services-auditing-tsx" */),
  "component---src-pages-services-business-registration-en-tsx": () => import("./../../../src/pages/services/business-registration.en.tsx" /* webpackChunkName: "component---src-pages-services-business-registration-en-tsx" */),
  "component---src-pages-services-business-registration-tsx": () => import("./../../../src/pages/services/business-registration.tsx" /* webpackChunkName: "component---src-pages-services-business-registration-tsx" */),
  "component---src-pages-services-consulting-en-tsx": () => import("./../../../src/pages/services/consulting.en.tsx" /* webpackChunkName: "component---src-pages-services-consulting-en-tsx" */),
  "component---src-pages-services-consulting-tsx": () => import("./../../../src/pages/services/consulting.tsx" /* webpackChunkName: "component---src-pages-services-consulting-tsx" */),
  "component---src-pages-services-en-tsx": () => import("./../../../src/pages/services.en.tsx" /* webpackChunkName: "component---src-pages-services-en-tsx" */),
  "component---src-pages-services-social-security-en-tsx": () => import("./../../../src/pages/services/social-security.en.tsx" /* webpackChunkName: "component---src-pages-services-social-security-en-tsx" */),
  "component---src-pages-services-social-security-tsx": () => import("./../../../src/pages/services/social-security.tsx" /* webpackChunkName: "component---src-pages-services-social-security-tsx" */),
  "component---src-pages-services-tax-planning-en-tsx": () => import("./../../../src/pages/services/tax-planning.en.tsx" /* webpackChunkName: "component---src-pages-services-tax-planning-en-tsx" */),
  "component---src-pages-services-tax-planning-tsx": () => import("./../../../src/pages/services/tax-planning.tsx" /* webpackChunkName: "component---src-pages-services-tax-planning-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

